import React from 'react'
import './App.css';
import { Footer, Blog, Possibility, Features, Aboutus, Header, Contactus } from './containers';
import {Brand, CTA, Navbar} from './components';
// import {BrowserRouter} from 'react-router-dom';
// import {HashLink as Link} from 'react-router-hash-link';

window.onbeforeunload = function () {
  window.scrollTo({top: 0,behavior: "instant"});
}

// var scrollToTop = window.setInterval(function() {
//   var pos = window.pageYOffset;
//   if ( pos > 0 ) {
//       window.scrollTo( 0, pos - 50 );
//   } else {
//       window.clearInterval( scrollToTop );
//   }
// }, 16);

const App = () => { 
  return (
    <>
      <div className="App">
        <div className= "gradient__bg">

          <Navbar /> 
        </div>
        <Header />   
        {/* <br/> */}
        <Aboutus />
        <Brand />
        <Features />
        {/* <Possibility /> */}
        <CTA />
        {/* <Blog /> */}
        <Footer />
      </div>
    </>
  );
}

export default App
