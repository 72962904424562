import React from 'react';
import './aboutus.css';

import gc from '../../assets/googlecloud.png';
import ui from '../../assets/uipath.png';
import ma from '../../assets/microsoftazure.png';
import aws from '../../assets/aws_certification.png';
import pmi from '../../assets/PMI_ACp.png';

const Aboutus = () => {
  return (
 <div className='calligo__aboutus_section'>   
<div className='calligo__aboutus' id='aboutus'>
  <div class="calligo__text_column calligo__content_element ">
    <div class="calligo__wrapper">
    <h2 class="calligo__heading text-md">Who We Are</h2>
    <hr className='calligo__hr'></hr>
    <p>
    At Calligo, we are a team of dedicated technology enthusiasts committed to delivering transformative IT solutions. Our extensive expertise across diverse industries enables us to craft customized strategies that address the specific needs and ambitions of each organization. With a keen understanding of the dynamic digital landscape, we excel in guiding clients through complex technological transitions, unlocking new avenues for growth and innovation.

Our dedication to excellence and relentless pursuit of innovation ensure that we provide our clients with advanced tools and insights, helping them maintain a competitive edge. We believe in fostering enduring partnerships built on trust and collaboration, making us the preferred choice for businesses looking to excel in the digital era.    </p>
    {/* <h3>Private Equity</h3>
    <p>For private equity organizations, Calligo IT offers full-stack IT advisory and M&amp;A services. Through extensive due diligence, carve-out, and platform creation, our team aims to drive business valuation and maximize portability.</p> */}
    <h3>Technology Services</h3>
    <p>
    We provide a comprehensive suite of technology services tailored to meet the diverse needs of modern businesses. Our offerings encompass innovative solutions in areas such as application development, cloud and data center services, IT infrastructure management, and beyond. By consistently investing in the latest technologies and methodologies, we ensure that our clients benefit from the most efficient and up-to-date solutions available.
    </p>
    </div>
    <br/>  
  </div>
{/* <div class="calligo__single_image calligo__content_element calligo__align_center">
  <figure class="calligo__wrapper-figure">
  <h2 class="calligo__heading text-md">Our Certifications</h2>
  <hr />
  <img src={ma} className="certification__image" />
  <img src={ui} className="certification__image"/>
  <img src={ma} className="certification__image" />

  </figure>
</div> */}
</div>
</div>
  )
}

export default Aboutus