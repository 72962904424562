import React from 'react';
import './blog.css';
const Blog = () => {
  return (
    <div className='calligo__blog'>
      
    </div>
  )
}

export default Blog