import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import MainLayout from "../layout/MainLayout";
import "../pages/aboutuspage.css";
import logo from "../assets/mission.png";
import logo1 from "../assets/ourplan.png";
import logo2 from "../assets/vision.png";
import { Brand } from "../components/";
import {CTA1} from '../components';

function AboutusPage() {
  return (
    <MainLayout>
      <div className="calligo-aboutus">
        <section id="about">
          <div class="container1">
            <h3>About Us</h3>
            <div Class="calligo-border"></div>
            <p>
            Welcome to Calligo IT Solutions, where we redefine excellence in the digital realm. As your premier partner in navigating the complexities of modern IT landscapes, we offer a comprehensive suite of advanced solutions designed to drive your business forward. Our expert team specializes in cutting-edge Application Development and Automation, seamless Cloud and Data Center Migration, and robust Cloud and Data Center Security. We excel in Data Center Virtualization and Consolidation, and deliver superior Enterprise Networking and Architecture solutions. Our IT Infrastructure Management and Managed Services are crafted to ensure optimal performance, while our innovative Robotic Process Automation (RPA) and meticulous Server, Network, Database, and Security Administration services provide unmatched reliability and efficiency.

With a commitment to leveraging the latest technologies and pioneering methodologies, we empower organizations to enhance operational efficiency, fortify their security posture, and achieve transformative growth. Trust Calligo to be your catalyst for success in the digital age.            </p>
            <div class="row about-cols">
              <div class="col-md-4 wow fadeInUp">
                <div class="about-col">
                  <div class="img">
                    <div class="icon">
                      <img
                        className="images"
                        src={logo}
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <h2 class="title">Our Mission</h2>
                  <p class="text-center">
                  Our company is dedicated to empowering businesses with cutting-edge technology solutions. We deliver exceptional value by understanding each client's unique needs, offering innovative, reliable IT services. Our commitment to excellence ensures clients excel in a competitive market.</p>                </div>
              </div>
              <div class="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                <div class="about-col">
                  <div class="img">
                    <div class="icon">
                      <img
                        className="images"
                        src={logo1}
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <h2 class="title">Our Plan</h2>
                  <p class="text-center">
                  We serve as a trusted partner, guiding clients through the complexities of the digital landscape. We invest in cutting-edge technologies and foster relationships built on trust, providing tailored solutions that seamlessly integrate with existing systems for optimal efficiency.
                  </p>
                </div>
              </div>
              <div class="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                <div class="about-col">
                  <div class="img">
                    <div class="icon">
                      <img
                        className="images"
                        src={logo2}
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <h2 class="title">Our Vision</h2>
                  <p class="text-center">
                  Our vision is to be a global leader in IT services, distinguished by innovative solutions and a steadfast commitment to client success. We aim to transform businesses through technology, enabling them to thrive in a dynamic digital landscape and achieve global growth.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="calligo--brand-color"><Brand/></div>
        <CTA1/>
      </div>
    </MainLayout>
  );
}

export default AboutusPage;
