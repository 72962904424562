import React from "react";
import MainLayout from "../../layout/MainLayout";
import "../Services/servicenine.css";
import { Brand } from "../../components";
import cdc from "../../assets/cloud and data center.png";
import {CTA1} from '../../components';

function Servicenine() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  return (
    <MainLayout>
            <div>
            <section class="c-section-nine">
    <h2 class="c-section-nine__title"><span> Server/Network/<br/>Database/Security Administration</span></h2>
    <h2 className="c-section-nine-title-main">Why Choose Us?</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Expert Administration </h3>
        <p>Our team comprises experienced administrators with deep expertise in managing servers, networks, databases, and security systems. We leverage industry best practices and advanced tools to ensure optimal performance and reliability.</p>
        </li>
        <li class="c-services__item">
        <h3>Comprehensive Solutions</h3>
        <p>Whether you need server administration, network management, database optimization, or security enhancements, we offer a full suite of administration services to meet your needs.</p>
        </li>
        <li class="c-services__item">
        <h3>Proactive Monitoring and Maintenance</h3>
        <p>We proactively monitor your infrastructure, identify potential issues, and take preventive action to minimize downtime and ensure continuous operation.</p>
        </li>
        <li class="c-services__item">
        <h3>Security Focus</h3>
        <p>Security is our top priority. We implement robust security measures and follow strict protocols to protect your data and infrastructure from cyber threats, ensuring compliance with industry regulations.</p>
        </li>
    </ul>
    <h2 className="c-section-nine-title-main">Our Services</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Server Administration </h3>
        <p>We manage your servers, ensuring they're properly configured, optimized for performance, and securely maintained.</p>
        </li>
        <li class="c-services__item">
        <h3>Network Management</h3>
        <p>We oversee your network infrastructure, ensuring smooth connectivity, optimal bandwidth utilization, and secure access.</p>
        </li>
        <li class="c-services__item">
        <h3>Database Administration</h3>
        <p>We optimize your databases for performance, reliability, and scalability, ensuring efficient data storage and retrieval.</p>
        </li>
        <li class="c-services__item">
        <h3>Security Administration</h3>
        <p>We enhance your security posture with proactive monitoring, threat detection, access controls, and compliance enforcement.</p>
        </li>
    </ul>
    </section>
    <CTA1/>
  </div >
        </MainLayout >
  );
}

export default Servicenine;
