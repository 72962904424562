import React from "react";
import MainLayout from "../../layout/MainLayout";
import '../Services/servicefour.css'
import { Brand } from '../../components';
import cdc from '../../assets/cloud and data center.png';
import {CTA1} from '../../components';

function Servicefour() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    return (
        <MainLayout>
            <div>
            <section class="c-section-four">
    <h2 class="c-section-four__title"><span>Data Center Virtualization and Consolidation</span></h2>
    <h2 className="c-section-four-title-main">Why Choose Us?</h2>
    <ul class="c-services">    
    <li class="c-services__item">
    <h3>Expertise</h3>
    <p>Our team consists of seasoned virtualization architects and consolidation specialists with extensive experience in designing and implementing virtualized environments. We leverage industry best practices to deliver solutions that meet your specific needs.</p>
    </li>
    <li class="c-services__item">
    <h3>Efficiency</h3>
    <p>Virtualization and consolidation allow you to do more with less. By reducing physical server footprint and consolidating workloads, you can achieve higher resource utilization, improved scalability, and simplified management.</p>
    </li>
    <li class="c-services__item">
    <h3>Cost Savings</h3>
    <p>Virtualization and consolidation not only reduce hardware and operational costs but also lower energy consumption and cooling requirements, leading to significant long-term savings.</p>
    </li>
    <li class="c-services__item">
    <h3>Scalability</h3>
    <p>Our solutions are designed to scale with your business. Whether you're a small business or a large enterprise, we can tailor virtualization and consolidation strategies to support your growth and evolving needs..</p>
    </li>
    </ul>
    <h2 className="c-section-four-title-main">Our Services</h2>
    <ul class="c-services">    
    <li class="c-services__item">
    <h3>Virtualization Assessment</h3>
    <p>We assess your current infrastructure, identify virtualization opportunities, and develop a customized virtualization roadmap tailored to your business goals.</p>
    </li>
    <li class="c-services__item">
    <h3>Consolidation Planning and Implementation</h3>
    <p>We help you consolidate servers, storage, and networking resources to optimize performance, streamline operations, and maximize ROI.</p>
    </li>
    <li class="c-services__item">
    <h3>Virtualization Management</h3>
    <p>Our experts provide ongoing monitoring, management, and support to ensure the stability, security, and efficiency of your virtualized environment.</p>
    </li>
    <li class="c-services__item">
    <h3>High Availability Solutions</h3>
    <p>Keep your critical applications running non-stop. We implement redundancy and failover mechanisms to minimize downtime, ensuring uninterrupted access to services.</p>
    </li>
    </ul>
    </section>
    <CTA1/>
  </div >
        </MainLayout >
    );
}

export default Servicefour;
