// import React from "react";
// import "../App.css";
// // import { Footer, Blog, Possibility, Features, Aboutus, Header, Contactus } from '../containers';
// // import {Brand, CTA, Navbar} from '../components';
// import logo from "../assets/clouddatacenter1.png";
// import logo1 from "../assets/cloudsecurity.png";
// import logo2 from "../assets/cloudinfrastructureservices.png";
// import logo3 from "../assets/datacentervirtualition.png";
// import logo4 from "../assets/enterprisenetwork.png";
// import logo5 from "../assets/serverdatabase.png";
// import logo6 from "../assets/cloudmigrating.png";
// import logo7 from "../assets/automationdevelopment.png";
// import logo8 from "../assets/roboticprocessautomation.png";
// import "./servicePage.css";
// import MainLayout from "../layout/MainLayout";
// // import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// // import {faCloudflare} from '@fortawesome/free-brands-svg-icons';
// import { Link } from "react-router-dom";

// const handleLinkClick = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };

// const servicePage = () => {
//   return (
//     <MainLayout>
//       <div>
//         <div Class="calligo-services-section">
//           <div Class="calligo-inner-width">
//             <h1 Class="calligo-section-title">Our Services</h1>
//             <div Class="calligo-border"></div>
//             <div Class="calligo-services-container">
//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/applicationdevelopmentautomation">
//                     <img src={logo7} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   Application Development and Automation
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Transform operations with our Application Development and
//                   Automation. Seamlessly integrate cloud-native applications for
//                   scalability. Centralized data management ensures reliability
//                   and security. Optimize infrastructure for peak performance,
//                   driving business success forward with efficiency and
//                   innovation{" "}
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/cloudanddatacentermigration">
//                     <img src={logo6} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   Cloud and Data Center Migration
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Accelerate your transformation with our Cloud and Data Center
//                   Migration expertise. Seamlessly transition to AWS, Azure, or
//                   GCP for enhanced scalability and flexibility. Ensure data
//                   integrity and security with our meticulous migration process.
//                   Unlock new efficiencies and possibilities for your business.
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/cloudanddatacentersecurity">
//                     <img src={logo1} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   Cloud and Data Center Security
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Elevate your operations with our Cloud and Data Center
//                   solutions. Seamlessly integrate cloud services for scalability
//                   and flexibility. Harness the power of centralized data
//                   management, ensuring reliability and security. Streamline your
//                   infrastructure for enhanced performance and efficiency,
//                   driving your business forward.
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/datacentervirutalization">
//                     <img src={logo} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   Data Center Virtualization and Consolidation
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Unlock the potential of your infrastructure with Data Center
//                   Virtualization. Consolidate resources, boost efficiency, and
//                   reduce costs through virtualization technology. Seamlessly
//                   manage workloads, enhance scalability, and ensure business
//                   continuity. Empower your organization with agile, adaptable
//                   infrastructure solutions tailored to your evolving needs.
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/entreprisenetworking">
//                     <img src={logo4} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   Enterprise Networking and Architecture
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Transform your connectivity with our Enterprise Networking
//                   solutions. Streamline operations, enhance collaboration, and
//                   drive innovation with secure, high-performance networks. From
//                   seamless integration to robust security, we deliver tailored
//                   solutions to optimize your network infrastructure, ensuring
//                   agility and reliability for your business-critical operations.
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/ITInfrastructuremanagement">
//                     <img src={logo2} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   IT Infrastructure Management
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Enhance your IT Infrastructure Management with our expertise.
//                   From network optimization to server maintenance, trust us to
//                   keep your systems running smoothly. We streamline operations,
//                   ensuring efficiency and reliability, empowering your business
//                   with seamless technology management.
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/ITManagedservices">
//                     <img src={logo3} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">IT Managed Services</div>
//                 <div Class="calligo-service-desc">
//                   Unlock the full potential of your IT landscape with our
//                   Managed Services. From network administration to security
//                   monitoring, trust us to keep your systems optimized and
//                   secure. We provide proactive support, ensuring seamless
//                   operations and empowering your business with peace of mind.{" "}
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/roboticprocessautomation">
//                     <img src={logo8} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   Robotic Process Automation (RPA)
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Embrace efficiency with Robotic Process Automation (RPA).
//                   Automate repetitive tasks and streamline workflows for
//                   increased productivity. Our solutions optimize processes,
//                   reduce errors, and free up valuable resources. Transform your
//                   operations with RPA, driving innovation and growth.
//                 </div>
//               </div>

//               <div Class="calligo-service-box">
//                 <div Class="calligo-service-icon">
//                   <Link to="/servernetworkdatabasesecurityadministration">
//                     <img src={logo5} alt="logo" onClick={handleLinkClick} />
//                   </Link>
//                 </div>
//                 <div Class="calligo-service-title">
//                   Server/Network/Database/Security Administration
//                 </div>
//                 <div Class="calligo-service-desc">
//                   Optimize your enterprise with our
//                   Server/Network/Database/Security Administration services.
//                   Ensure seamless operation across all fronts with our expert
//                   management. From robust security protocols to efficient
//                   database maintenance, we'll keep your systems running
//                   smoothly, empowering your business with reliability and
//                   resilience.
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </MainLayout>
//   );
// };

// export default servicePage;


import React, { useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import logo from "../assets/clouddatacenter1.png";
import logo1 from "../assets/cloudsecurity.png";
import logo2 from "../assets/cloudinfrastructureservices.png";
import logo3 from "../assets/datacentervirtualition.png";
import logo4 from "../assets/enterprisenetwork.png";
import logo5 from "../assets/serverdatabase.png";
import logo6 from "../assets/cloudmigrating.png";
import logo7 from "../assets/automationdevelopment.png";
import logo8 from "../assets/roboticprocessautomation.png";
import "./servicePage.css";
import MainLayout from "../layout/MainLayout";

const ServiceBox = ({ to, imgSrc, title, desc }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };

    
  return (
    <div className="calligo-service-box">
      <div className="calligo-service-icon">
        <Link to={to}>
          {loading && <div className="loader"></div>}
          <img
            src={imgSrc}
            alt="logo"
            style={{ display: loading ? "none" : "inline-block" }}
            onLoad={handleImageLoaded}
          />
        </Link>
      </div>
      <div className="calligo-service-title">{title}</div>
      <div className="calligo-service-desc">{desc}</div>
    </div>
  );
};

const ServicePage = () => {
  
  return (
    <MainLayout>
      <div>
        <div className="calligo-services-section">
          <div className="calligo-inner-width">
            <h1 className="calligo-section-title">Our Services</h1>
            <div className="calligo-border"></div>
            <div className="calligo-services-container">
              <ServiceBox
                to="/applicationdevelopmentautomation"
                imgSrc={logo7}
                title="Application Development and Automation"
                desc="Transform operations with our Application Development and Automation. Seamlessly integrate cloud-native applications for scalability. Centralized data management ensures reliability and security. Optimize infrastructure for peak performance, driving business success forward with efficiency and innovation"
              />
              <ServiceBox
                to="/cloudanddatacentermigration"
                imgSrc={logo6}
                title="Cloud and Data Center Migration"
                desc="Accelerate your transformation with our Cloud and Data Center Migration expertise. Seamlessly transition to AWS, Azure, or GCP for enhanced scalability and flexibility. Ensure data integrity and security with our meticulous migration process. Unlock new efficiencies and possibilities for your business."
              />
              <ServiceBox
                to="/cloudanddatacentersecurity"
                imgSrc={logo1}
                title="Cloud and Data Center Security"
                desc="Elevate your operations with our Cloud and Data Center solutions. Seamlessly integrate cloud services for scalability and flexibility. Harness the power of centralized data management, ensuring reliability and security. Streamline your infrastructure for enhanced performance and efficiency, driving your business forward."
              />
              <ServiceBox
                to="/datacentervirtualization"
                imgSrc={logo}
                title="Data Center Virtualization and Consolidation"
                desc="Unlock the potential of your infrastructure with Data Center Virtualization. Consolidate resources, boost efficiency, and reduce costs through virtualization technology. Seamlessly manage workloads, enhance scalability, and ensure business continuity. Empower your organization with agile, adaptable infrastructure solutions tailored to your evolving needs."
              />
              <ServiceBox
                to="/entreprisenetworking"
                imgSrc={logo4}
                title="Enterprise Networking and Architecture"
                desc="Transform your connectivity with our Enterprise Networking solutions. Streamline operations, enhance collaboration, and drive innovation with secure, high-performance networks. From seamless integration to robust security, we deliver tailored solutions to optimize your network infrastructure, ensuring agility and reliability for your business-critical operations."
              />
              <ServiceBox
                to="/ITInfrastructuremanagement"
                imgSrc={logo2}
                title="IT Infrastructure Management"
                desc="Enhance your IT Infrastructure Management with our expertise. From network optimization to server maintenance, trust us to keep your systems running smoothly. We streamline operations, ensuring efficiency and reliability, empowering your business with seamless technology management."
              />
              <ServiceBox
                to="/ITManagedservices"
                imgSrc={logo3}
                title="IT Managed Services"
                desc="Unlock the full potential of your IT landscape with our Managed Services. From network administration to security monitoring, trust us to keep your systems optimized and secure. We provide proactive support, ensuring seamless operations and empowering your business with peace of mind."
              />
              <ServiceBox
                to="/roboticprocessautomation"
                imgSrc={logo8}
                title="Robotic Process Automation (RPA)"
                desc="Embrace efficiency with Robotic Process Automation (RPA). Automate repetitive tasks and streamline workflows for increased productivity. Our solutions optimize processes, reduce errors, and free up valuable resources. Transform your operations with RPA, driving innovation and growth."
              />
              <ServiceBox
                to="/servernetworkdatabasesecurityadministration"
                imgSrc={logo5}
                title="Server/Network/Database/Security Administration"
                desc="Optimize your enterprise with our Server/Network/Database/Security Administration services. Ensure seamless operation across all fronts with our expert management. From robust security protocols to efficient database maintenance, we'll keep your systems running smoothly, empowering your business with reliability and resilience."
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ServicePage;
