import React from 'react';
import './header.css';

// import Slider from './Slider';
import Carousel from './Carousal';

const Header = () => {
  return (
    // <div className='calligo__header section__padding' id='home'>
    //   <div className='calligo__header-content'></div>
    //   <h1 className='gradient__text'>IT Transformation & Cybersecurity Advisory</h1>
    //   <p>IT Transformation Simplified</p>
    //   <div className='calligo__header-content__input'>
    //     <input type='email' placeholder='Your Email address'></input>
    //     <button type='button'>Get Started</button>
    //   </div> 

      <div className='calligo__header-slider'>
        {/* <Slider /> */}
          <Carousel />
      </div>
    // </div> 
  )
}


export default Header;

