// import React from "react";
// import "./brand.css";


// const Brand = () => {
//   return (
//     // <div className='calligo__brand'>
//     //   <div class="calligo_wrapper">
//     //     <p>
//     //     <div class="callligo__custom_heading">8</div>
//     //     <div  class="callligo__custom_heading_s">Years in Business</div>
//     //     </p>
//     //     <p>
//     //     <div  class="callligo__custom_heading">100+</div>
//     //     <div class="callligo__custom_heading_s">Total Employees Across the Country</div>
//     //     </p>
//     //     <p>
//     //     <div class="callligo__custom_heading">50+</div>
//     //     <div class="callligo__custom_heading_s">Skills/
//     //     Certifications</div>
//     //     </p>
//     //     {/* <p>
//     //     <div class="callligo__custom_heading">200 Million</div>
//     //     <div class="callligo__custom_heading_s">Security Events Processed Daily</div>
//     //     </p> */}
//     //     <p>
//     //     <div class="callligo__custom_heading">15</div>
//     //     <div class="callligo__custom_heading_s">Partnerships/
//     //     Alliances</div>
//     //     </p>
//     //     <p>
//     //     <div class="callligo__custom_heading">10+</div>
//     //     <div class="callligo__custom_heading_s">Private Equity Transactions Annually</div>
//     //     </p>
//     //     <p>
//     //     <div  class="callligo__custom_heading"> 100+</div>
//     //     <div class="callligo__custom_heading_s">Years of Combined Leadership Experience</div>
//     //     </p>
//     //   </div>
//     // </div>
//     <div class="brand-carousel section-padding owl-carousel">
//       <div class="single-logo">
//         <img src="https://i.postimg.cc/t4w94PSN/logo1.png" alt="" />
//       </div>
//       <div class="single-logo">
//         <img src="https://i.postimg.cc/t4w94PSN/logo1.png" alt="" />
//       </div>
//       <div class="single-logo">
//         <img src="https://i.postimg.cc/t4w94PSN/logo1.png" alt="" />
//       </div>
//       <div class="single-logo">
//         <img src="https://i.postimg.cc/t4w94PSN/logo1.png" alt="" />
//       </div>
//       <div class="single-logo">
//         <img src="https://i.postimg.cc/t4w94PSN/logo1.png" alt="" />
//       </div>
//       <div class="single-logo">
//         <img src="https://i.postimg.cc/t4w94PSN/logo1.png" alt="" />
//       </div>
//     </div>
//   );
// };

// export default Brand;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./brand.css";
import microsoft from '../../assets/microsoft-logo.png';
import vmware from '../../assets/vmware-png.png';
import marta from '../../assets/marta-png.png';
import nutanix from '../../assets/nutanix-png.png';
import cisco from '../../assets/cisco-png.png';
import lazerlogi from '../../assets/lazerlogistics-png.png';
import rockdale from '../../assets/rockdalecountry-png.png';
import ICC from '../../assets/ICC-png.png';
import kivu from '../../assets/Kivu-png.png';

const Brand = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="brand-carousel section-padding">
              <header className="calligo-brand-header">Key Clients</header>
      <Slider {...settings}>
        <div className="single-logo">
          <img src={microsoft} alt="" />
        </div>
        <div className="single-logo">
          <img src={vmware} alt="" />
        </div>
        <div className="single-logo">
          <img src={marta} alt="" />
        </div>
        <div className="single-logo">
          <img src={nutanix} alt="" />
        </div>
        <div className="single-logo">
          <img src={cisco} alt="" />
        </div>
        <div className="single-logo-lazer">
          <img src={lazerlogi} alt="" />
        </div>
        <div className="single-logo">
          <img src={rockdale} alt="" />
        </div>
        <div className="single-logo">
          <img src={ICC} alt="" />
        </div>
        <div className="single-logo">
          <img src={kivu} alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default Brand;
