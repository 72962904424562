import React from "react";
import MainLayout from "../../layout/MainLayout";
import '../Services/serviceseven.css'
import { Brand } from '../../components';
import cdc from '../../assets/cloud and data center.png';
import {CTA1} from '../../components';

function Serviceseven() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    return (
        <MainLayout>
            <div>
            <section class="c-section-seven">
    <h2 class="c-section-seven__title"><span> IT Managed Services</span></h2>
    <h2 className="c-section-seven-title-main">Why Choose Us?</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Expertise </h3>
        <p>Our team consists of highly skilled IT professionals with years of experience in managing diverse IT environments. From networks and servers to endpoints and cloud services, we have the expertise to keep your IT infrastructure running optimally.</p>
        </li>
        <li class="c-services__item">
        <h3>Proactive Monitoring and Maintenance</h3>
        <p>We believe in proactive rather than reactive IT management. Our advanced monitoring tools detect issues before they become problems, allowing us to take preventive action and minimize downtime.</p>
        </li>
        <li class="c-services__item">
        <h3>24/7 Technical Support</h3>
        <p>IT issues can arise at any time, which is why our team is available round the clock to provide technical support and assistance whenever you need it. Rest assured that help is just a phone call or email away.</p>
        </li>
        <li class="c-services__item">
        <h3>Scalability</h3>
        <p>Whether you're a small startup or a large enterprise, our managed IT services are designed to scale with your business. We can tailor our solutions to meet your specific needs and accommodate your growth.</p>
        </li>
    </ul>
    <h2 className="c-section-seven-title-main">Our Services</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Network Management </h3>
        <p>We monitor and manage your network infrastructure to ensure optimal performance, security, and reliability.</p>
        </li>
        <li class="c-services__item">
        <h3>Server Management</h3>
        <p>We oversee the health and performance of your servers, ensuring they're running smoothly and efficiently at all times.</p>
        </li>
        <li class="c-services__item">
        <h3>Endpoint Security</h3>
        <p>We protect your endpoints from cyber threats with robust security measures and proactive monitoring.</p>
        </li>
        <li class="c-services__item">
        <h3>Cloud Services Management</h3>
        <p>We manage your cloud services, ensuring they're configured correctly, optimized for performance, and secure.</p>
        </li>
    </ul>
    </section>
    <CTA1/>
  </div >
        </MainLayout >
    );
}

export default Serviceseven;
