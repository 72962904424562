import React from 'react';
import './features.css';
const Features = () => {
  return (
    <div className='calligo__testimonal'>
      <h2 class="calligo__testimonal-title">Testimonial</h2>
      <br/>
      <hr/>
      <div className='calligo__testimonal-wrapper' >
        <div class="calligo__testimonal-wrap">
          {/* <img src="http://lorempixel.com/80/80" alt="user"/> */}
          <div class="calligo__testimonal-content">
            
          Calligo IT Solutions provides exceptional service and knowledge for all of our needs.  They’re quick to respond and are a resource we trust to provide top tier support and recommendations.  I highly recommend them for any fast-paced, forward-thinking, entrepreneurial organization.</div>
          <p class="calligo__testimonal-author">
          Jeremy Teeters​, Lazerspot
          </p>
        </div>
        {/* <div class="calligo__testimonal-wrap">
          <div class="calligo__testimonal-content">
            We've been working with Calligo for our cloud and network management needs, and the results speak for themselves. Their team's deep understanding of our business requirements coupled with their technical proficiency has enabled us to scale seamlessly while staying within budget. Highly recommend their services.  </div>
          <p class="calligo__testimonal-author">
            John Doe, CEO Company
          </p>
        </div>
        <div class="calligo__testimonal-wrap">
          <div class="calligo__testimonal-content">
            Calligo's cloud and network management services have been instrumental in revolutionizing our IT infrastructure. Their personalized approach and dedication to understanding our unique challenges set them apart. Thanks to their innovative solutions and ongoing support, we've experienced a remarkable improvement in performance and reliability.  </div>
          <p class="calligo__testimonal-author">
            John Doe, CEO Company
          </p>
        </div> */}
      </div>
      {/* <div className='calligo__testimonal-end'>
      <span><br/></span>
    </div> */}
    </div>
  )
}

export default Features