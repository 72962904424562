import React from "react";
import MainLayout from "../../layout/MainLayout";
import '../Services/servicesix.css'
import { Brand } from '../../components';
import cdc from '../../assets/cloud and data center.png';
import {CTA1} from '../../components';

function Servicesix() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    return (
        <MainLayout>
        <div>
        <section class="c-section-six">
<h2 class="c-section-six__title"><span>IT Infrastructure Management</span></h2>
<h2 className="c-section-six-title-main">Why Choose Us?</h2>
<ul class="c-services">    
    <li class="c-services__item">
    <h3>Expertise </h3>
    <p> Our team consists of highly skilled IT professionals with years of experience in managing complex infrastructure environments. We stay updated with the latest technologies and best practices to ensure that your infrastructure operates at peak performance.</p>
    </li>
    <li class="c-services__item">
    <h3>Proactive Maintenance</h3>
    <p>We believe in proactive rather than reactive maintenance. Our team monitors your infrastructure round the clock, identifies potential issues before they escalate into problems, and takes preventive measures to keep your systems running smoothly.</p>
    </li>
    <li class="c-services__item">
    <h3>Security and Compliance</h3>
    <p>Data security and compliance are our top priorities. We implement robust security measures and best practices to protect your sensitive information and ensure that your infrastructure meets industry regulations and standards.</p>
    </li>
    <li class="c-services__item">
    <h3>Scalability</h3>
    <p>Whether you're a small business or a large enterprise, our infrastructure management services are designed to scale with your needs. We can tailor our solutions to accommodate your growth and evolving requirements.</p>
    </li>
</ul>
<h2 className="c-section-six-title-main">Our Services</h2>
<ul class="c-services">    
    <li class="c-services__item">
    <h3>Network and Server Management</h3>
    <p> We monitor and manage your network devices and servers to ensure optimal performance, availability, and security.</p>
    </li>
    <li class="c-services__item">
    <h3>Data Backup and Disaster Recovery</h3>
    <p> We implement backup and disaster recovery solutions to protect your data and ensure business continuity in the event of an unforeseen disaster.</p>
    </li>
    <li class="c-services__item">
    <h3>Security and Compliance</h3>
    <p> We assess your security posture, identify vulnerabilities, and implement measures to protect your infrastructure from cyber threats and ensure compliance with regulations.</p>
    </li>
    <li class="c-services__item">
    <h3>Performance Optimization</h3>
    <p>Maximize the efficiency of your IT infrastructure with our performance optimization services. We conduct thorough assessments to identify bottlenecks and areas for improvement. Our experts then implement optimization strategies to enhance system speed, reliability, and scalability, ensuring your infrastructure meets the demands of your business operations.</p>
    </li>
</ul>
</section>
<CTA1/>
</div >
    </MainLayout >
    );
}

export default Servicesix;
