import React from "react";
import MainLayout from "../../layout/MainLayout";
import '../Services/servicethree.css'
import { Brand } from '../../components';
import cdc from '../../assets/cloud and data center.png';
import {CTA1} from '../../components';


function Servicethree() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    return (
        <MainLayout>
            <div>
            <section class="c-section-three">
    <h2 class="c-section-three__title"><span>Cloud and Data Center Security</span></h2>
    <h2 className="c-section-three-title-main">Why Choose Us?</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Expertise </h3>
        <p>Our team consists of cybersecurity experts with extensive experience in securing cloud and data center environments. We stay ahead of emerging threats and trends to ensure your infrastructure remains protected at all times.</p>
        </li>
        <li class="c-services__item">
        <h3>Comprehensive Solutions</h3>
        <p>Whether you're using public cloud platforms like AWS, Azure, or GCP, or managing your data centers on-premises, we offer a wide range of security solutions to meet your needs.</p>
        </li>
        <li class="c-services__item">
        <h3>Customized Approach</h3>
        <p>We understand that every business is unique. That's why we work closely with you to assess your security posture, identify vulnerabilities, and develop customized security strategies that align with your business objectives.</p>
        </li>
        <li class="c-services__item">
        <h3>Continuous Monitoring and Compliance</h3>
        <p>Our security solutions include continuous monitoring and compliance checks to ensure that your infrastructure meets industry standards and regulatory requirements.</p>
        </li>
    </ul>
    <h2 className="c-section-three-title-main">Our Services</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Cloud Security Assessment </h3>
        <p>We assess your cloud infrastructure's security posture, identify vulnerabilities, and provide recommendations to strengthen your security defenses.</p>
        </li>
        <li class="c-services__item">
        <h3>Data Center Security</h3>
        <p>We help secure your on-premises data centers with robust security measures, including network segmentation, access controls, and threat detection systems.</p>
        </li>
        <li class="c-services__item">
        <h3>Security Operations Center (SOC) Services:</h3>
        <p>Our SOC services provide 24/7 monitoring, threat detection, and incident response to proactively defend against cyber threats.</p>
        </li>
        <li class="c-services__item">
        <h3>Incident Response Planning</h3>
        <p>We help you create and implement effective plans to swiftly respond to security incidents, minimizing damage and downtime. </p>
        </li>
    </ul>
    </section>
    <CTA1/>
  </div >
        </MainLayout >
    );
}

export default Servicethree;
