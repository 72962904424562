import React, { useState } from 'react';
import axios from 'axios';
import './cta.css';

const CTA = () => {
  const [email, setEmail] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail({
      ...email,
      [name]: value
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      // await axios.post('/nodejsapp',email);
      await axios.post('/sendmail/sendmail.php',{
        name: email.name,
        company: email.company,
        email: email.email,
        phone: email.phone,
        message: email.message
      });
      alert('Email sent successfully!');
      // Clear form fields after submission
      setEmail({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email. Please try again later.');
    }
  };

  return (
    <div className='calligo__contactus' id='contactus'>
      <div className="container">
        <h1 className="title">Feel Free to Contact Us Today And Secure Your Solution</h1>
        <div className="wrapper animated bounceInLeft">
          <div className="contact">
            <form id="contactForm" onSubmit={sendEmail}>
              <p className="name-field">
                <label>Name <span>*</span></label>
                <input type="text" name="name" value={email.name} onChange={handleChange} required/>
              </p>
              <p className="company-field">
                <label>Company</label>
                <input type="text" name="company" value={email.company} onChange={handleChange}/>
              </p>
              <p className="email-field">
                <label>Email <span>*</span></label>
                <input type="email" name="email" value={email.email} onChange={handleChange} required/>
              </p>
              <p className="phone-field">
                <label>Phone</label>
                <input type="text" name="phone" value={email.phone} onChange={handleChange}/>
              </p>
              <p className="message-field full">
                <label>Message</label>
                <textarea name="message" rows="5" value={email.message} onChange={handleChange}></textarea>
              </p>
              <p className="required-field">Required field <span>*</span></p>
              <p className="submit-button">
                <button type="submit">Submit</button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTA;