import React, {useState, useEffect} from "react";
import MainLayout from "../../layout/MainLayout";
import "../Services/serviceone.css";
import { Brand } from "../../components";
import cdc from "../../assets/cloud and data center.png";
import {CTA1} from '../../components';


function Serviceone() {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const img = new Image();
  //   img.src = '../../assets/Ada.png';
  //   img.onload = () => setLoading(false);
  // }, []);
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  return (
    <MainLayout>
            <div>
            <section class="c-section-one">
    <h2 class="c-section-one__title"><span>Application Development and Automation</span></h2>
    <h2 className="c-section-one-title-main">Why Choose Us?</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Expertise </h3>
        <p>Our team consists of seasoned developers and automation specialists with years of experience in their respective fields. We stay updated with the latest technologies and trends to deliver cutting-edge solutions.</p>
        </li>
        <li class="c-services__item">
        <h3>Comprehensive Solutions</h3>
        <p>We understand that one size does not fit all. That's why we work closely with you to understand your requirements and develop tailor-made solutions that align with your business goals.</p>
        </li>
        <li class="c-services__item">
        <h3>Efficiency</h3>
        <p>With our automation services, we help streamline your processes, eliminate manual tasks, and boost productivity. Spend less time on repetitive tasks and more time on what matters most - growing your business.</p>
        </li>
        <li class="c-services__item">
        <h3>Scalability</h3>
        <p>Whether you're just starting out or looking to scale your operations, our solutions are designed to grow with your business. From MVPs to full-scale applications, we've got the expertise to support you at every stage.</p>
        </li>
    </ul>
    <h2 className="c-section-one-title-main">Our Services</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Custom Application Development</h3>
        <p> From mobile apps to web applications, we build intuitive and scalable solutions that delight users and drive business results.</p>
        </li>
        <li class="c-services__item">
        <h3>Process Automation</h3>
        <p>Say goodbye to manual tasks and hello to efficiency. We automate repetitive processes, freeing up your time to focus on innovation and growth.</p>
        </li>
        <li class="c-services__item">
        <h3>Consulting and Support</h3>
        <p> Need guidance on your digital transformation journey? Our experts are here to provide strategic advice and ongoing support to ensure your success.</p>
        </li>
        <li class="c-services__item">
        <h3>Data Analytics and Insights</h3>
        <p>Leverage your data for actionable insights. From collection to visualization, we offer tailored analytics solutions. Drive growth and strategic decisions with comprehensive data analysis and predictive modeling.</p>
        </li>
    </ul>
    </section>
        <CTA1/>
  </div >
        </MainLayout >
  );
}

export default Serviceone;
