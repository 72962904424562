import React from 'react';
import  ReactDOM  from 'react-dom';
import App from './App';
import './index.css';
import ServicePage from './pages/ServicePage'
// ReactDOM.render(<App />, document.getElementById('root'));

import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
import AboutusPage from './pages/AboutusPage';
import Serviceone from './pages/Services/Serviceone';
import Servicetwo from './pages/Services/Servicetwo';
import Servicethree from './pages/Services/Servicethree';
import Servicefour from './pages/Services/Servicefour';
import Servicefive from './pages/Services/Servicefive';
import Servicesix from './pages/Services/Servicesix';
import Serviceseven from './pages/Services/Serviceseven';
import Serviceeight from './pages/Services/Serviceeight';
import Servicenine from './pages/Services/Servicenine';

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>,
    },
    {
        path: "services",
        element: <ServicePage/>,
    },
    {
      path:'/aboutus',
      element: <AboutusPage/>,
    },
    {
      path:'/applicationdevelopmentautomation',
      element: <Serviceone/>,
    },
    {
      path:'/cloudanddatacentermigration',
      element: <Servicetwo/>,
    },
    {
      path:'/cloudanddatacentersecurity',
      element: <Servicethree/>,
    },
    {
      path:'/datacentervirtualization',
      element: <Servicefour/>,
    },
    {
      path:'/entreprisenetworking',
      element: <Servicefive/>,
    },
    {
      path:'/ITInfrastructuremanagement',
      element: <Servicesix/>,
    },
    {
      path:'/ITManagedservices',
      element: <Serviceseven/>,
    },
    {
      path:'/roboticprocessautomation',
      element: <Serviceeight/>,
    },
    {
      path:'/servernetworkdatabasesecurityadministration',
      element: <Servicenine/>,
    }
  ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router}/>
);