import React from "react";
import MainLayout from "../../layout/MainLayout";
import '../Services/servicefive.css'
import { Brand } from '../../components';
import cdc from '../../assets/cloud and data center.png';
import {CTA1} from '../../components';

function Servicefive() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    return (
        <MainLayout>
        <div>
        <section class="c-section-five">
<h2 class="c-section-five__title"><span>Enterpise Networking and Architecture</span></h2>
<h2 className="c-section-five-title-main">Why Choose Us?</h2>
<ul class="c-services">    
    <li class="c-services__item">
    <h3>Expertise </h3>
    <p>Our team consists of highly skilled network engineers and architects with years of experience in designing, implementing, and managing enterprise-grade networks. We stay ahead of the curve with the latest technologies and best practices to deliver innovative solutions that drive business success.</p>
    </li>
    <li class="c-services__item">
    <h3>Custom Solutions</h3>
    <p>We understand that every business is unique. That's why we work closely with you to assess your requirements, understand your goals, and develop customized networking and architecture solutions that align with your strategic objectives.</p>
    </li>
    <li class="c-services__item">
    <h3>Reliability</h3>
    <p>We prioritize reliability and uptime in everything we do. Whether it's building a resilient network infrastructure or designing a scalable architecture, our solutions are engineered for maximum performance and availability.</p>
    </li>
    <li class="c-services__item">
    <h3>Scalability</h3>
    <p>Our solutions are designed to scale with your business. Whether you're a small startup or a large enterprise, we have the expertise to support your growth and adapt to your evolving needs.</p>
    </li>
</ul>
<h2 className="c-section-five-title-main">Our Services</h2>
<ul class="c-services">    
    <li class="c-services__item">
    <h3>Network Design and Implementation </h3>
    <p>We design and deploy secure, high-performance networks tailored to your business requirements, ensuring seamless connectivity and optimal performance.</p>
    </li>
    <li class="c-services__item">
    <h3>Architecture Assessment and Optimization</h3>
    <p>We assess your existing architecture, identify areas for improvement, and implement strategies to optimize performance, security, and scalability.</p>
    </li>
    <li class="c-services__item">
    <h3>Advanced Technologies Integration</h3>
    <p>From SD-WAN and cloud networking to IoT and edge computing, we help you leverage the latest technologies to drive innovation and gain a competitive edge.</p>
    </li>
    <li class="c-services__item">
    <h3>Network Security Assessment</h3>
    <p>Enhance network integrity with our thorough assessments. We identify vulnerabilities, assess risks, and provide tailored solutions for heightened security. Protect your assets from cyber threats with our expert evaluation </p>
    </li>
</ul>
</section>
<CTA1/>
</div >
    </MainLayout >
    );
}

export default Servicefive;
