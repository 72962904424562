import React from "react";
import MainLayout from "../../layout/MainLayout";
import "../Services/serviceeight.css";
import { Brand } from "../../components";
import cdc from "../../assets/cloud and data center.png";
import {CTA1} from '../../components';

function Serviceeight() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  return (
    <MainLayout>
            <div>
            <section class="c-section-eight">
    <h2 class="c-section-eight__title"><span>Robotic Process Automation (RPA)</span></h2>
    <h2 className="c-section-eight-title-main">Why Choose Us?</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Expertise </h3>
        <p>Our team comprises RPA experts with extensive experience in designing, implementing, and managing automation solutions. We have successfully automated a wide range of processes across various industries, delivering tangible results for our clients.</p>
        </li>
        <li class="c-services__item">
        <h3>Customized Solutions</h3>
        <p>We understand that every business has unique requirements. That's why we work closely with you to identify processes that can be automated and develop customized RPA solutions that align with your business goals.</p>
        </li>
        <li class="c-services__item">
        <h3>Fast ROI</h3>
        <p>RPA enables you to achieve significant cost savings and productivity gains in a short amount of time. By automating repetitive and manual tasks, you can free up valuable time and resources to focus on more strategic initiatives.</p>
        </li>
        <li class="c-services__item">
        <h3>Scalability</h3>
        <p>Our RPA solutions are designed to scale with your business. Whether you're looking to automate a single process or multiple processes across different departments, we have the expertise to support your growth and expansion.</p>
        </li>
    </ul>
    <h2 className="c-section-eight-title-main">Our Services</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Process Assessment and Automation</h3>
        <p> We assess your existing processes, identify opportunities for automation, and develop customized RPA solutions to streamline workflows and improve efficiency.</p>
        </li>
        <li class="c-services__item">
        <h3>Bot Development and Deployment</h3>
        <p>Our team develops and deploys RPA bots tailored to automate specific tasks or entire processes, reducing errors, and increasing throughput.</p>
        </li>
        <li class="c-services__item">
        <h3>Monitoring and Optimization</h3>
        <p>We provide ongoing monitoring and optimization services to ensure that your RPA bots are performing optimally and delivering maximum value to your organization.</p>
        </li>
        <li class="c-services__item">
        <h3>Robotic Data Entry</h3>
        <p>Revolutionize data entry tasks with our robotic data entry solutions. Our automation technology efficiently extracts, validates, and enters data from various sources into your systems with precision and accuracy. Say goodbye to manual data entry errors and delays, and hello to streamlined data processing workflows.</p>
        </li>
    </ul>
    </section>
    <CTA1/>
  </div >
        </MainLayout >
  );
}

export default Serviceeight;
