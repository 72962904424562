import React from "react";
import MainLayout from "../../layout/MainLayout";
import "../Services/servicetwo.css";
import { Brand } from "../../components";
import cdc from "../../assets/cloud and data center.png";
import {CTA1} from '../../components';


function Servicetwo() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  return (
    <MainLayout>
            <div>
            <section class="c-section-two">
    <h2 class="c-section-two__title"><span>Cloud and Data Center Migration</span></h2>
    <h2 className="c-section-two-title-main">Why Choose Us?</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Expertise </h3>
        <p>Our team consists of certified cloud architects and migration specialists with extensive experience in AWS, Azure, and GCP. We have successfully migrated numerous businesses to the cloud, ensuring minimal disruption and maximum efficiency.</p>
        </li>
        <li class="c-services__item">
        <h3>Comprehensive Solutions</h3>
        <p> Whether you're looking to migrate to a single cloud platform or a hybrid/multi-cloud environment, we've got you covered. Our solutions are tailored to your specific needs and objectives.</p>
        </li>
        <li class="c-services__item">
        <h3>Risk-Free Migration</h3>
        <p>We understand the challenges and risks involved in cloud migration. That's why we follow industry best practices and employ proven methodologies to mitigate risks and ensure a smooth transition.</p>
        </li>
        <li class="c-services__item">
        <h3>Cost Optimization</h3>
        <p>Our experts help you optimize your cloud infrastructure to minimize costs while maximizing performance and scalability. Say goodbye to over-provisioning and unnecessary expenses.</p>
        </li>
    </ul>
    <h2 className="c-section-two-title-main">Our Services</h2>
    <ul class="c-services">    
        <li class="c-services__item">
        <h3>Cloud Strategy and Assessment</h3>
        <p>We assess your current infrastructure, understand your business goals, and develop a customized cloud migration strategy.</p>
        </li>
        <li class="c-services__item">
        <h3>Migration Planning and Execution</h3>
        <p>From planning and discovery to migration and optimization, we handle the entire migration process with precision and care.</p>
        </li>
        <li class="c-services__item">
        <h3>Post-Migration Support</h3>
        <p>Our support doesn't end after migration. We provide ongoing monitoring, optimization, and support to ensure your cloud environment remains efficient and secure.</p>
        </li>
        <li class="c-services__item">
        <h3>Legacy System Modernization</h3>
        <p> Upgrade outdated systems for agility and cost-effectiveness. We assess, refactor, and migrate legacy applications to modern cloud environments, enhancing performance and competitiveness.</p>
        </li>
    </ul>
    </section>
    <CTA1/>
  </div >
        </MainLayout >
  );
}

export default Servicetwo;
