import React from 'react'
import './cta1.css';
function CTA1() {
  return (
    <div> <section id="calligo-call-to-action" class="wow fadeIn">
    <div class="calligo-container text-center">
      <h3>Struggling to navigate your business's direction?</h3>
      <br/>
      <p class="calligo-text-center">
        With Calligo, Elevate your business with our comprehensive
        services! Take the first step towards success by exploring our
        tailored solutions today.
      </p>
      <br/>
      <a class="calligo-cta-btn" href="#contactus">
        Get Started
      </a>
    </div>
  </section>
  <br/>
  </div>
  )
}

export default CTA1;