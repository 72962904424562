import React, { useState } from "react";
import "./navbar.css";
import logo from "../../assets/brand-removebg.png";
import { RiMenu3Line, RiClosein, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const Menu = () => (
  <>
    <p></p>
    <p></p>
    <p></p>
    <p>
      <Link to="/" className="menu-links">
        Home
      </Link>
    </p>
    <p>
      <Link to="/aboutus" className="menu-links" id="#aboutus">
        About us
      </Link>
    </p>
    <p className="dropdown">
      <Link to="/services" className="menu-links">
        {" "}
        Services
      </Link>
      <div className="dropdown-content">
        <Link to="/applicationdevelopmentautomation" className="menu-links">
          {" "}
          Application Development and Automation
        </Link>
        <Link to="/cloudanddatacentermigration" className="menu-links">
          {" "}
          cloud and Datacenter Migration (AWS/AZURE/GCP)
        </Link>
        <Link to="/cloudanddatacentersecurity" className="menu-links">
          {" "}
          cloud and Datacenter Security
        </Link>
        <Link to="/datacentervirtualization" className="menu-links">
          {" "}
          Data Center Virtualization and Consolidation
        </Link>
        <Link to="/entreprisenetworking" className="menu-links">
          {" "}
          Enterprise Networking and Architecture
        </Link>
        <Link to="/ITInfrastructuremanagement" className="menu-links">
          {" "}
          IT Infrastructure Management
        </Link>
        <Link to="/ITManagedservices" className="menu-links">
          {" "}
          IT Managed Services
        </Link>
        <Link to="/roboticprocessautomation" className="menu-links">
          {" "}
          Robotic Process Automation (RPA)
        </Link>
        <Link
          to="/servernetworkdatabasesecurityadministration"
          className="menu-links"
        >
          {" "}
          Server/Network/Database/Security Administration
        </Link>
      </div>
    </p>
    {/* <p><a href="#possibility" className='menu-links'>Private Equity</a></p> */}
    <p>
      <a href="#contactus" className="menu-links" id="#contactus">
        Contact Us
      </a>
    </p>
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="calligo__navbar">
      <div className="calligo__navbar-links">
        <div className="calligo__navbar-links_logo">
          <Link to="/" className="menu-links">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="calligo__navbar-links_container">
          <Menu />
        </div>
      </div>
      {/* <div className='calligo__navbar-sign'>
        <p>Sign in</p>
        <button type='button'>Sign up</button>
      </div> */}
      <div className="calligo__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="calligo__navbar-menu_container scale-up-center">
            <div className="calligo__navbar-menu_container-links">
              <Menu />
              {/* <div className='calligo__navbar-menu_container-links-sign'>
                  <p>Sign in</p>
                  <button type='button'>Sign up</button>
                </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
