import React from 'react'
import { Footer, Blog, Possibility, Features, Aboutus, Header, Contactus } from '../containers';
import {Brand, CTA, Navbar} from '../components';

function MainLayout({children}) {
  return (
    <div>
        <div className= "gradient__bg">
            <Navbar />  
            </div>
            <div>{children}</div>
            <CTA />
            <Footer />
        </div>
  )
}

export default MainLayout