import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import 'bootstrap/dist/css/bootstrap.css'; 
import "./carousal.css";

import cdc from '../../assets/cloud and data center.png';
import cis from '../../assets/cloud and infrastructure security.png';
import cims from '../../assets/cloud infrastructure managed services.png';
import dcv from '../../assets/data center virtualization.png';
import ntw from '../../assets/network.png';
import sdp from '../../assets/server and database platforms.png';
import clm from '../../assets/cloud migration.png';
import ada from '../../assets/application-development-and-automation.png';
import rpa from '../../assets/robotic-process-automation.png';
import { Link } from 'react-router-dom';




function Carousels() {

  const [index, setIndex] = useState(0); 
  const handleSelect = (selectedIndex) => { 
      setIndex(selectedIndex);
  } 
  return (
    <div className='calligo__header-carousals'> 
    <Carousel activeIndex={index} onSelect={handleSelect}>
    <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {ada} alt="" />
          <h1 class="calligo__header-overlay">
            <b>Application Development and Automation</b>
            <p>Transform operations with our Application Development and Automation. Seamlessly integrate cloud-native applications for scalability. Centralized data management ensures reliability and security. Optimize infrastructure for peak performance, driving business success forward with efficiency and innovation</p>
            <Link to='/applicationdevelopmentautomation'><button>Learn More</button></Link>
          </h1>          
        </div> 

      </Carousel.Item>
      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {cdc} alt="" />
          <h1 class="calligo__header-overlay">
            <b>Cloud and Data Center Migration(AWS/AZURE/GCP)</b>
            <p>Accelerate your transformation with our Cloud and Data Center Migration expertise. Seamlessly transition to AWS, Azure, or GCP for enhanced scalability and flexibility. Ensure data integrity and security with our meticulous migration process. Unlock new efficiencies and possibilities for your business.</p>
            <Link to='/cloudanddatacentermigration'><button>Learn More</button></Link>
          </h1>          
        </div> 

      </Carousel.Item>
      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {cis} alt="" />
          <h1 class="calligo__header-overlay">
            <b>Cloud and Data Center Security</b>
            <p>Elevate your operations with our Cloud and Data Center solutions. Seamlessly integrate cloud services for scalability and flexibility. Harness the power of centralized data management, ensuring reliability and security. Streamline your infrastructure for enhanced performance and efficiency, driving your business forward.</p>
            <Link to='/cloudanddatacentersecurity'><button>Learn More</button></Link>
            </h1>
        </div> 
      </Carousel.Item>

      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {dcv} alt="" />
          <h1 class="calligo__header-overlay">
            <b>Data Center Virtualization and Consolidation</b>
            <p>Unlock the potential of your infrastructure with Data Center Virtualization. Consolidate resources, boost efficiency, and reduce costs through virtualization technology. Seamlessly manage workloads, enhance scalability, and ensure business continuity. Empower your organization with agile, adaptable infrastructure solutions tailored to your evolving needs.</p>
            <Link to='/datacentervirtualization'><button>Learn More</button></Link>
            </h1>
        </div> 
      </Carousel.Item>

      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {ntw} alt="" />
          <h1 class="calligo__header-overlay">
            <b>Enterprise Networking and Architecture</b>
            <p>Transform your connectivity with our Enterprise Networking solutions. Streamline operations, enhance collaboration, and drive innovation with secure, high-performance networks. From seamless integration to robust security, we deliver tailored solutions to optimize your network infrastructure, ensuring agility and reliability for your business-critical operations."</p>
            <Link to='/entreprisenetworking'><button>Learn More</button></Link>
            </h1>
        </div>  
      </Carousel.Item>

      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {cims} alt="" />
          <h1 class="calligo__header-overlay">
            <b>IT Infrastructure Management</b>
            <p>Enhance your IT Infrastructure Management with our expertise. From network optimization to server maintenance, trust us to keep your systems running smoothly. We streamline operations, ensuring efficiency and reliability, empowering your business with seamless technology management.</p>
            <Link to='/ITInfrastructuremanagement'><button>Learn More</button></Link>
            </h1>
        </div> 
      </Carousel.Item>

      
      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {clm} alt="" />
          <h1 class="calligo__header-overlay">
            <b>IT Managed Services</b>
            <p>Unlock the full potential of your IT landscape with our Managed Services. From network administration to security monitoring, trust us to keep your systems optimized and secure. We provide proactive support, ensuring seamless operations and empowering your business with peace of mind.</p>
            <Link to='/ITManagedservices'><button>Learn More</button></Link>
            </h1>
        </div> 
      </Carousel.Item>

      
      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {rpa} alt="" />
          <h1 class="calligo__header-overlay">
            <b>Robotic Process Automation (RPA)</b>
            <p>Embrace efficiency with Robotic Process Automation (RPA). Automate repetitive tasks and streamline workflows for increased productivity. Our solutions optimize processes, reduce errors, and free up valuable resources. Transform your operations with RPA, driving innovation and growth.</p>
            <Link to='/roboticprocessautomation'><button>Learn More</button></Link>
            </h1>
        </div> 
      </Carousel.Item>

      <Carousel.Item className="calligo__header_caroual-item" interval={3000}> 
        <div className='calligo__header-image_container'>
          <img src= {sdp} alt="" />
          <h1 class="calligo__header-overlay">
            <b>Server/Network/Database/<br/>Security Administration</b>
            <p>Optimize your enterprise with our Server/Network/Database/Security Administration services. Ensure seamless operation across all fronts with our expert management. From robust security protocols to efficient database maintenance, we'll keep your systems running smoothly, empowering your business with reliability and resilience.</p>
            <Link to='/servernetworkdatabasesecurityadministration'><button>Learn More</button></Link>
            </h1>
        </div> 
      </Carousel.Item>

    </Carousel>
    </div>
  )
  
}

export default Carousels;